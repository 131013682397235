import React from "react"
import { Result, Button, Row, Col } from "antd"
import { Link } from "gatsby"
import Shell from "../components/Shell/Shell"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    textAlign: `center`,
    padding: `5% 0`,
    background: `#fff`,
  },
}

const colProps = {
  xs: 24,
}

const resultProps = {
  status: "404",
  title: "404",
  subTitle:
    "Hmmm... That page doesn't seem to be here. Or if it was, it's not anymore.",
  extra: (
    <Button type="primary">
      <Link to="/">Take Me Home</Link>
    </Button>
  ),
}

export default () => (
  <Shell>
    <Row {...rowProps}>
      <Col {...colProps}>
        <Result {...resultProps} />
      </Col>
    </Row>
  </Shell>
)
